import React from "react"
import SEO from "./seo"
import { StaticQuery, graphql } from "gatsby"

export default () => (
<StaticQuery
    query={graphql`
      query {
        allContentfulOuticksHeader{
          edges {
            node {
             logo {
              file {
                url
              }
             } 
            }
          }    
        }
     }
    `}
render={data => (
<>
<SEO title="Time Off Management - outicks.com"/>
<header className="header ">
    <nav className="navbar navbar-default navbar-fixed-top  hestia_left">
        <div className="container">
            <div className="navbar-header">
                <div className="title-logo-wrapper">
                    <a className="navbar-brand" href="/" title="outicks.com">
                      <img src={data.allContentfulOuticksHeader.edges[0].node.logo.file.url} alt="outicks.com"/></a>
                </div>
                <div className="navbar-toggle-wrapper">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#main-navigation">
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="sr-only">Toggle Navigation</span>
                    </button>
                </div>
            </div>
            <div id="main-navigation" className="collapse navbar-collapse">
                <ul id="menu-primary-menu" className="nav navbar-nav">
                    <li id="menu-item-88" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-88 active"><a title="Features" href="/#features">Features</a></li>
                    <li id="menu-item-87" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-87 active"><a title="Our Values" href="/#values">Our Values</a></li>
                    <li id="menu-item-90" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-90 active"><a title="Integrations" href="/#integrations">Integrations</a></li>
                    <li id="menu-item-89" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-89 active"><a title="Pricing" href="/#pricing">Pricing</a></li>
                    <li id="menu-item-91" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-91"><a title="Login" href="https://app.outicks.com">Login</a></li>
                    <li id="menu-item-92" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-92"><a title="Sign Up" href="https://app.outicks.com/account/register">Sign Up</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <div style={{display: 'none'}}></div>
</header>
</>
)}
/>
)
