import React from "react"

const Footer = () => (
<footer className="footer footer-black footer-big">
    <div className="container">

        <div className="content">
            <div className="row">
                <div className="col-md-4">
                    <div id="nav_menu-4" className="widget widget_nav_menu">
                        <h5>Product</h5>
                        <div className="menu-footer-product-menu-container">
                            <ul id="menu-footer-product-menu" className="menu">
                                <li id="menu-item-144" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-44 current_page_item menu-item-144"><a href="/">Time Off Management</a></li>
                                <li id="menu-item-146" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-146"><a href="/#features">Features</a></li>
                                <li id="menu-item-153" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-153"><a href="/#values">Our Values</a></li>
                                <li id="menu-item-154" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-154"><a href="/#integrations">Integrations</a></li>
                                <li id="menu-item-155" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-155"><a href="/#pricing">Pricing</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div id="nav_menu-5" className="widget widget_nav_menu">
                        <h5>Company</h5>
                        <div className="menu-footer-company-menu-container">
                            <ul id="menu-footer-company-menu" className="menu">
                                <li id="menu-item-152" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-152"><a href="https://pmtsit.com/">Prometheus Inspiring Technology</a></li>
                                <li id="menu-item-150" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-150"><a href="/terms-of-service">Terms of Service</a></li>
                                <li id="menu-item-151" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-151"><a href="/privacy-policy">Privacy Policy</a></li>
                                <li id="menu-item-149" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-149"><a href="/data-processing-policy">Data Processing Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div id="custom_html-4" className="widget_text widget widget_custom_html">
                        <div className="textwidget custom-html-widget">&copy; {new Date().getFullYear()} Prometheus</div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div className="hestia-bottom-footer-content">
            <ul id="menu-footer-product-menu-1" className="footer-menu pull-left">
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-44 current_page_item menu-item-144"><a href="https://outicks.com/">Time Off Management</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-146"><a href="/#features">Features</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-153"><a href="/#values">Our Values</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-154"><a href="/#integrations">Integrations</a></li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-155"><a href="/#pricing">Pricing</a></li>
            </ul>
        </div>
    </div>
</footer>
)

export default Footer