import React from "react"

import Header from '../components/header'
import Footer from '../components/footer'


export default class Terms extends React.Component {

render(){
return(
<>  
  <div className="wrapper default ">
    <Header />

      <div id="primary" className="boxed-layout-header page-header header-small" data-parallax="active">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1 text-center">
              <h1 className="hestia-title ">Terms of Service</h1>
            </div>
          </div>
        </div>
        <div className="header-filter header-filter-gradient"></div>
      </div>

      <div className="main  main-raised ">
        <div className="blog-post ">
          <div className="container">
            <article id="post-128" className="section section-text">
              <div className="row">
                <div className="col-md-8 page-content-wrap  col-md-offset-2">
                  <div data-elementor-type="post" data-elementor-id="128" className="elementor elementor-128" data-elementor-settings="[]">
                    <div className="elementor-inner">
                      <div className="elementor-section-wrap">
                        <section data-id="f7c177f" className="elementor-element elementor-element-f7c177f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                          <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-row">
                              <div data-id="f9bda3b" className="elementor-element elementor-element-f9bda3b elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                <div className="elementor-column-wrap  elementor-element-populated">
                                  <div className="elementor-widget-wrap">
                                    <div data-id="32737a7" className="elementor-element elementor-element-32737a7 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                      <div className="elementor-widget-container">
                                        <div className="elementor-text-editor elementor-clearfix">
                                          <div style={{direction: 'ltr', textAlign: 'left'}}>
                                            Terms  of  Service
                                            Last revised: February 5th, 2019
                                            Prometheus Inspiring Technology Ltd. (“<b>Prometheus</b>” or the “<b>Company</b>”) welcomes you (the “<b>User</b>”, or “<b>Customer</b>”, or “<b>you</b>”) to our Outicks™ web and mobile application(s) (the <b>“App(s)</b>” or “<b>Platform</b>”) and to our website available at: https://outicks.com (the “<b>Site</b>”) (collectively, the “<b>Proper Time Service</b>” or “<b>Service</b>” as further detailed below).
                                            <h1>Acceptance</h1>
                                            <ol>
                                              <li><span dir="LTR"></span>By accepting these <b><u>Terms of Service</u></b>, (“<b>Terms</b>” or “<b>Agreement</b>”) you confirm that you are over the age of 18 years old and that the Service which the Company provides to you is based on your age confirmation undertaking.</li>
                                              <li><span dir="LTR"></span>By accepting the Terms, you declare that you have read and understood the content and the meaning of the binding, enforceable, Agreement which you, or any User / Customer on your behalf, have knowingly agreed to enter into a binding contractual engagement with the Company.</li>
                                              <li><span dir="LTR"></span>By accepting the Terms, you declare that you have read and understood the Company’s <b><u>Privacy Policy</u></b><span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>and you confirm that the Service or the Company does not breach any of your rights, in the jurisdiction which the Service is being provided to you.</li>
                                              <li><span dir="LTR"></span>By accepting the Terms, you declare that you will make use of the Service only for the Service’s purpose and according to the law.</li>
                                            </ol>
                                            IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICE
                                            <h1>The Service</h1>
                                            <ol start="5">
                                              <li><span dir="LTR"></span>Outicks™ is a proprietary web and mobile Platform allowing you to manage projects, communicate with other users and share information with them.</li>
                                              <li><span dir="LTR"></span>Outicks™ is Software as A Service (SAAS) which uses third parties’ platforms in order to provide the Service. Third parties may provide storage and processing solutions.</li>
                                              <li><span dir="LTR"></span>Outicks™ will act lawfully according to Data Protection legislation, including the EU legislation (“GDPR”) with regard to the Service, which applies on Data Processors. The Company is not responsible to determine the legal requirements and the law which apply on User’s activity with the Service. Customer has the sole responsibility for the User’s personal data and the lawfulness of any personal data which is processed or stored by User while using the Service.</li>
                                              <li><span dir="LTR"></span>YOU SHOULD NOT USE THE SERVICE IN CONJUNCTION WITH THE PERSONAL DATA IF SUCH USE BREACHS DATA PROTECTION LAWS.</li>
                                              <li><span dir="LTR"></span>In order to connect to the Service, you need to register using your business email address and a secured password. Passwords are encrypted and saved in the Application Database. All network communication is secured using SSL technology.</li>
                                              <li><span dir="LTR"></span>During the connectivity to the Service, the Platform may collect, store and process text strings, photos, videos, documents, digital formats of plans and other content which User can document while and for the use of the Service.</li>
                                              <li><span dir="LTR"></span>Our Privacy Policy reflects the Service’s data protection and data management policy and procedures.</li>
                                              <li><span dir="LTR"></span>Customer is the controller of the personal data stored and processed through the Service and Customer acknowledge that the Company has no control over the data, including personal data, Customer uploads, processes and stores while using the Service.</li>
                                              <li><span dir="LTR"></span>By using the Service, User may share photos and other information, including personal data, to other users or contacts that User wishes to communicate with regard to the Service.</li>
                                              <li><span dir="LTR"></span>The Service collects only public information or information User uploaded to the Platform or shared with User’s organization members which are using the Service. Please refer to the Service’s Privacy Policy available at: <span><a href="/privacy-policy">https://outicks.com/privacy-policy</a></span>.</li>
                                              <li><span dir="LTR"></span>User is the only Controller of the information uploaded on the App and Customer defines the permissions of each User to be able to share information, including personal data, with other Users of the same Customer. Company will have no liability, and will not bear any loss, as a result of personal data breach occurred as a result of User’s failure to protect personal data as required by law.</li>
                                              <li><span dir="LTR"></span>You, User, Customer acknowledge and confirm that any information<span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>uploaded by Customer’s user may be visible to other Customer’s user, as Customer defines and control. Customer releases the Company from any claim which may arise as a result of Use’s failure to comply with Customer’s policy and the Service settings controlled by Customer.</li>
                                              <li>
                                                <span dir="LTR"></span>Free Trial Period
                                                <ol>
                                                  <li><span dir="LTR"></span>The Service is provided free for initial trial period of 21 days from registration.</li>
                                                  <li><span dir="LTR"></span>the following terms shall have the following meanings</li>
                                                </ol>
                                              </li>
                                            </ol>
                                            <b>User</b> – A single person using the Service.
                                            <b>Organization</b> – One or more Users sharing an account to work on project/projects and share information with other Organization Users.
                                            <ol>
                                              <li><span dir="LTR"></span>Upon Client’s signup for a Free Trial Period Client will be granted access to the Service by creating Client with a one User account and one Organization account managed by User.</li>
                                              <li><span dir="LTR"></span>Trial period may be used once per account. When the trial period ends, the account will be locked, while allowing User to start a paid subscription by choosing a Service plan and a payment source.</li>
                                              <li><span dir="LTR"></span>The Company may update from time to time the Service plans, their contents, features and fees, subject to the Company’s notice to Customers pre-defined Person of Contact.</li>
                                              <li><span dir="LTR"></span>You must provide accurate and complete information for creating the account and you agree not to misrepresent your identity or your account’s information.</li>
                                              <li><span dir="LTR"></span>User must not disclose personal passwords to third parties and Customer will take measures to supervise the activity conducted by all Customer’s users is according to the applicable law.</li>
                                              <li><span dir="LTR"></span>All actions which are taken by User while using the Service are regarded as the Customer’s permitted actions. If Customer becomes aware that an account was compromised or breached, it should contact the Company immediately on <span><a href="mailto:team@outicks.com">team@outicks.com</a></span></li>
                                            </ol>
                                            &nbsp;
                                            <h1>User Generated Content</h1>
                                            <ol start="22">
                                              <li><span dir="LTR"></span>The App may allow you to upload, post, publish and make available copyrightable materials such as literary works, text, images, photos, videos, strips and any other proprietary materials (the “<b>User Generated Content</b>”). Such User Generated Content shall remain at all times, and to the extent permitted by law, the respective property of its owner.</li>
                                              <li><span dir="LTR"></span>Customer and User are the sole responsible for any User Generated Content generated while using the Service. Customer will keep the Company from any harm, which may result from unlawful use of the Service and third party’s infringements of intellectual property rights, or data protection law, or any other applicable law which may be valid to User Generated Content.</li>
                                              <li><span dir="LTR"></span>Customer confirms that it is the rightful owner of the User Generated Content and that all licenses, permits, authorizations, consents have been obtained lawfully by Customer prior to its’ Users use of the Service.</li>
                                              <li><span dir="LTR"></span>Company may access the User Generated Content and use it for the purpose of providing the Service, and Customer confirms that such use by the Company of the User Generated Content does not infringe, or breach, any third party’s rights.</li>
                                              <li><span dir="LTR"></span>User must not post or upload any User Generated Content which is unlawful for User to control, process, send or upload in the country in which User and/or Company are considered residents.</li>
                                              <li><span dir="LTR"></span>User must use the Service with regard to User Generated Content according to the Customer’s internal procedure and instructions. The Company reserves its right to remove Such User Generated Content without any prior notice in any case and at its sole discretion.</li>
                                              <li><span dir="LTR"></span>When you upload, post, send, publish, use or make available any User Generated Content, you grant the Company an irrevocable, perpetual, non-exclusive, royalty-free, transferable, assignable, sub-licensable and worldwide license, to use, reproduce, distribute, transmit, make derivative works of, display, copy, make available and perform that User Generated Content, along with your name, details, trade-marks, service-marks and logos, only in connection with the Service and/or Site, whether through the Internet, any mobile device or otherwise, in any media formats and through any media channels available today or will become available in the future.</li>
                                              <li><span dir="LTR"></span>Customer’s User Generated Content may also be processed or stored by third parties, which the company may use from time to time as sub-contractors. Customer confirms that it has reviewed and estimated the vulnerabilities of online storage by third-parties sub-contractors while providing their services to the Company.</li>
                                              <li><span dir="LTR"></span>Customer responsibility to back up on Customers premises all User Generated Content which may contain information which required storing / keeping under applicable law. Company is not liable to any loss of Customer’s User Generated Data which Customer’s did, or did not, back-up.</li>
                                              <li><span dir="LTR"></span></li>
                                            </ol>
                                            <h1>User Representations and Undertakings</h1>
                                            <ol start="32">
                                              <li><span dir="LTR"></span>User undertakes to use the Service according to the law and to the Terms. User may not use the Service if the Service, or the Terms breaches, contradicts, violates any applicable legislation or third party’s rights.</li>
                                              <li><span dir="LTR"></span>User understand that the Company provides the Service also by using sub-contractors which the Company has no control over and the Company relies on those sub-contractors contractual undertakings.</li>
                                              <li><span dir="LTR"></span>User obtained the required consents from third parties to store and process data by using the Service.</li>
                                            </ol>
                                            &nbsp;
                                            <h1>Use Restrictions</h1>
                                            <ol start="35">
                                              <li><span dir="LTR"></span>The Company, at its own discretion, may enforce restrictions on any of the <u>following listed actions</u> and manipulations on User’s account:</li>
                                              <li><span dir="LTR"></span>Copy, modify, adapt, translate, reverse engineering, reverse-compile, disassemble, publicly display or distribute in any way, the content of the Service, including any information, material or data available on the Service (collectively, the “<b>Content</b>”).</li>
                                              <li><span dir="LTR"></span>Make any use of Content on any other website or networked computer environment for any purpose, or replicate or copy Content without Company’s prior written authorization.</li>
                                              <li><span dir="LTR"></span>Create a browser environment or other demarcation of the information around Outicks’s Content (no frames or inline linking).</li>
                                              <li><span dir="LTR"></span>Infringe or violate privacy rights or any other rights of another User, or gather personally identifiable information of the Company Users without their explicit consent, including – Using “Robot”, ”Spider”, editing applications, or other manual or automatic device or process to retrieve, index, or data-mine.</li>
                                              <li><span dir="LTR"></span>Defame, slander, abuse, stalk, harass, threaten, or otherwise harm the lawful legal rights of others.</li>
                                              <li><span dir="LTR"></span>Transfer, implement or create in any other way, in connection with the Service, any computer virus, ”worm”, ”Trojan Horse”, bug, spyware, malware, or any other computer code, file or programs that may damage, or are intended to damage the activity of any hardware, software or telecommunications equipment or component or code.</li>
                                              <li><span dir="LTR"></span>Damage the operation of the App(s) or Service or disturb the Apps/(s) or Service. Impairing or interfering with the servers or networks that host the Service or allow the availability, or disobey any requirements, procedures, policies or regulations of these servers or networks providers.</li>
                                              <li><span dir="LTR"></span>Sell, license or exploit for any commercial purpose any use or access to the Service (or any Content therein) without the Company’s prior written consent. Assign (e.g. frame) or copy (e.g. mirror) any part of the Service without the Company’s prior written consent.</li>
                                              <li><span dir="LTR"></span>Create a database – by systematically downloading and storing all or any part of the Service, Content and User Generated Content.</li>
                                              <li><span dir="LTR"></span>Use the Service for any purpose that is unlawful, unethical, immoral or unauthorized.</li>
                                              <li><span dir="LTR"></span>Use of the Service and/or the Content for commercial or non-private purposes without the Company’s prior written consent.</li>
                                              <li><span dir="LTR"></span>Install and/or use the Service on a simulator or an emulator.</li>
                                            </ol>
                                            Such restrictions may result a pause or a termination of the Service to a Customer or any of the Customer’s Users.
                                            <h1> Privacy Policy</h1>
                                            &nbsp;
                                            <ol start="36">
                                              <li><span dir="LTR"></span>Prometheus respects your privacy and is committed to protect the personal information Users share, send, post, upload, store and process while using the Service.</li>
                                              <li><span dir="LTR"></span>Our full updated Privacy Policy can be reviewed at any time in the following link: https://outicks.com/privacy-policy</li>
                                              <li><span dir="LTR"></span>The use of the Service is subject to the acceptance and approval of the Privacy Policy by User.</li>
                                              <li><span dir="LTR"></span>The Company processes personal data according to the Company’s DATA PROCESSING POLICY annexed to the Privacy Policy.</li>
                                              <li><span dir="LTR"></span>User which becomes aware of a breach of its’ privacy rights according to the Privacy Policy, or that Company’s Privacy Policy contradicts Users local legislation, User will stop immediately using the Service and will notify the Company about the contradiction or breach.</li>
                                              <li><span dir="LTR"></span>The Company will respond to User within reasonable time, if Company may cure the breach or contradiction for the Customer, or that the Service cannot be provided to User.</li>
                                              <li><span dir="LTR"></span>User is the sole controller of the data, including personal data, used while using the Service.</li>
                                              <li><span dir="LTR"></span></li>
                                            </ol>
                                            <h1>Intellectual Property Rights</h1>
                                            <ol start="44">
                                              <li><span dir="LTR"></span>The Service, the Content, the design, logos, graphics, images, as well as the selection, assembly and arrangement, Prometheus’s proprietary software, algorithms and all intellectual property rights pertaining thereto, including inventions, patents and patent applications, trademarks, trade names, copyrightable materials, (including the “look and feel” of the Service and part of it), specifications, methods, processes, algorithms, data, technical information materials, technology, interactive features, source code and objects, files, interfaces, interface graphics and trade secrets, whether or not registered or registrable (collectively: “<b>Intellectual Property</b>”) are owned by the Company, or licensed to the Company.</li>
                                              <li><span dir="LTR"></span>You may not copy, distribute, display, publicly perform, make available to the public, disassemble, separate, modify, sublicense to, commercial use, sell, rent, lend, process, connect, combine with other software, translate, modify, or create derivative works of any material that is subject to Prometheus’ proprietary rights, including Prometheus’ intellectual property, either by yourself or by someone acting on your behalf, in any way or by any mean.</li>
                                              <li><span dir="LTR"></span>All logos and other proprietary identifiers used by Prometheus in connection with the Service are all trademarks and/or trade names of Prometheus, whether or not registered.</li>
                                              <li><span dir="LTR"></span>All other trademarks, service marks, trade names and logos, which may appear on or with respect to the Service, belong to their respective owners (“<b>Third Party Marks</b>”).</li>
                                              <li><span dir="LTR"></span>No right, license, or interest to Prometheus Trademarks and/or to the Third-Party Marks is granted hereunder, and you agree that no such right, license, or interest shall be asserted by you with respect to Prometheus Trademarks or the Third Party Marks and therefore you will not use any of those marks, unless the Company approved in writing such use.</li>
                                              <li><span dir="LTR"></span>You cannot remove or delete any copyright notices, restrictions and signs indicating proprietary rights of Prometheus and/or its licensors, including copyright mark [©] or trademark [® or ™] contained in or accompanying the Service, and User represents and warrants that it will abide all applicable laws in this respect.</li>
                                              <li><span dir="LTR"></span>User is prohibited from using, diluting or staining any name, mark or logo that is identical or confusingly similar to any of Prometheus marks and logos, whether registered or not.</li>
                                            </ol>
                                            <h1>Minors</h1>
                                            <ol start="51">
                                              <li><span dir="LTR"></span>You may use the Service only if you are over (18) years old. We reserve the right to require from you at any stage to provide proof of age, and, if applicable, approval of your use of the Service by your legal guardian, so that we can verify that only eligible Users are using the Service.</li>
                                              <li><span dir="LTR"></span>In the event that it comes to our knowledge that a User’s age is under the age of eighteen (18), The Company reserves the right to prohibit such use and block such User from accessing the Service and will make all efforts to promptly delete any Personal Information (as such term is defined in our Privacy Policy with respect to such User).</li>
                                            </ol>
                                            <h1>Third Party Services</h1>
                                            <ol start="53">
                                              <li><span dir="LTR"></span>The Service may be linked, supported, stored on certain third-party websites and other third-party services (collectively: “<b>Third Party Services</b>”). Such Third-Party Services are independent from the Service.</li>
                                              <li><span dir="LTR"></span>User acknowledges that Prometheus has no control over such Third-Party Services, and further acknowledges and agrees that Prometheus is not responsible for the availability of Third Party Services, and Company is not liable for any goods, services, content, advertisements, products, or any other materials available on and/or through such Third-Party Services.</li>
                                              <li><span dir="LTR"></span>User further acknowledges and agrees that Prometheus shall not be liable, directly or indirectly, for any damage or loss whatsoever caused, or alleged to be caused, by or in connection with use of or reliance on any goods, services, content, products or other materials available on or through any Third-Party Services.</li>
                                              <li><span dir="LTR"></span>Third Party Services provide legal documents, including terms of use and privacy policy, governing the use of each such Third-Party Services, contents and services. Please review these legal documents carefully before using any such Third-Party Services.</li>
                                            </ol>
                                            <h1>Third Party Components</h1>
                                            <ol start="57">
                                              <li><span dir="LTR"></span>The Service may implement, or include third party’s manufactured software, files and components that are subject to open source and third-party license agreements (“<b>Third Party Components</b>”).</li>
                                              <li><span dir="LTR"></span>Your right to use such Third-Party Components as part of, or in connection with the Service, is subject to any applicable acknowledgements and license terms accompanying such Third-Party Components, contained therein or related thereto. If there is a conflict between the licensing terms of such Third-Party Components and these Terms, the licensing terms of the Third Party Components shall prevail only in connection with the related Third Party Components.</li>
                                              <li><span dir="LTR"></span>These Terms do not apply to any Third-Party Components accompanying or contained in the Service and Prometheus disclaims all liability related thereto.</li>
                                              <li><span dir="LTR"></span>You acknowledge that Prometheus is not the author, owner or licensor of any Third-Party Components, and that Prometheus makes no warranties or representations, express or implied, as to the quality, capabilities, operations, performance or suitability of Third Party Components. Under no circumstances shall the Service or any portion thereof (except for the Third-Party Components contained therein) be deemed to be “open source” or “publicly available” software.</li>
                                            </ol>
                                            <h1>Usage Rules</h1>
                                            <ol start="61">
                                              <li><span dir="LTR"></span>Since User may download the App(s) from a third party’s platform, service provider or distributor (e.g. App Store or Google Play Store), (“<b>Platform Provider</b>”) your use of the App(S) may also be governed by usage rules which the Platform Provider may have established and which relate to your use of the App(s) (“<b>Usage Rules</b>”). In such case, Usage Rules may apply and it is your responsibility to determine what other Usage Rules are applicable to your use of the App.</li>
                                              <li><span dir="LTR"></span>You undertake to comply with all the applicable Platform Provider’s Usage Rules. The terms of the Usage Rules may prevail over any other Terms. You represent that you are not prohibited by any applicable laws or Usage Rules from downloading and/or using the App(s).</li>
                                              <li><span dir="LTR"></span>Any download and/or use of the App(s) by anyone prohibited by any applicable laws or Usage Rules from downloading and/or using the App(s) is expressly prohibited.</li>
                                              <li><span dir="LTR"></span>You acknowledge and agree that Platform Provider has no responsibility for the App(s) or content thereof, nor does it have any obligation whatsoever to furnish any maintenance and support services with respect to the App(s).</li>
                                              <li><span dir="LTR"></span>In the event of any failure of an App downloaded from a Platform Provider to conform to any applicable warranty, you may notify the relevant Platform Provider, and Platform Provider may as the sole remedy of the Platform Provider (if it chooses or obligated by law to do so) refund the purchase price, if any, for the App(s) to you to the maximum extent permitted by applicable law. The Platform Provider may have no other warranty obligation whatsoever with respect to the App(s), and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be solely governed by these Terms and any law applicable to Prometheus as provider of the App(s).</li>
                                              <li><span dir="LTR"></span>You acknowledge that Platform Provider may not be responsible for addressing any claims by you or any third party, relating to the App(s) or your possession and/or use of the App(s), including, but not limited to: (i) product liability and intellectual property claims; (ii) any claim that the App(s) fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation; and all such claims are governed solely by these Terms and any law applicable to Prometheus as provider of the App(s).</li>
                                              <li><span dir="LTR"></span>You acknowledge and agree that if you downloaded the App(s) from a Platform Provider, and its subsidiaries, these are third party beneficiaries of these Terms, and that, upon your acceptance of the Terms, the Platform Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary thereof.</li>
                                            </ol>
                                            <h1>Availability</h1>
                                            <ol start="68">
                                              <li><span dir="LTR"></span>The Service availability and functionality depends on various factors, such as communication networks software, hardware, and Prometheus’s service providers and sub-contractors.</li>
                                              <li><span dir="LTR"></span>Prometheus does not warrant or guarantee that the Service will operate and/or will be available at all times without disruption or interruption, or that it will be immune from unauthorized access error-free.</li>
                                            </ol>
                                            <h1>Changes to the Service</h1>
                                            <ol start="70">
                                              <li><span dir="LTR"></span>The Company reserves the right to modify, correct, amend, enhance, improve, make any other changes to, or discontinue, temporarily or permanently, the Service (or any part thereof, including but not limited to the Content) without notice, at any time and at its sole discretion.</li>
                                              <li><span dir="LTR"></span>User hereby acknowledges that the content provided under the Service may be changed, extended in terms of content / forms, or removed at any time without any notice to you.</li>
                                              <li><span dir="LTR"></span>You agree that Prometheus shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service or the Content included in the Service.</li>
                                            </ol>
                                            <h1>Disclaimer and Warranties</h1>
                                            <ol start="73">
                                              <li><span dir="LTR"></span>THE COMPANY DOES NOT WARRANT OR MAKES ANY REPRESENTATIONS REGARDING THE USE, THE INABILITY TO USE<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>,</span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> AND OPERATE, OR THE RESULTS OF THE USE OF THE SERVICE BY USER.</li>
                                              <li><span dir="LTR"></span>THE SERVICE (AND ANY PART THEREOF), INCLUDING WITHOUT LIMITATION ANY CONTENT, DATA AND INFORMATION RELATED THERETO, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED,</li>
                                              <li><span dir="LTR"></span>THE COMPANY AND ITS AFFILIATES, INCLUDING ANY OF THEIR OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS, AGENTS, PARENT COMPANIES, SUBSIDIARIES AND OTHER RELATED COMPANIS (COLLECTIVELY, “<b>PROMETHEUS AFFILIATES</b>”), JOINTLY AND SEVERALLY, DISCLAIM AND MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE USABILITY, ACCURACY, QUALITY, AVAILABILITY, RELIABILITY, SUITABILITY, COMPLETENESS, TRUTHFULNESS, USEFULNESS, OR EFFECTIVENESS OF ANY CONTENT, DATA, RESULTS, OR OTHER INFORMATION OBTAINED OR GENERATED IN CONNECTION WITH ANY USER’S USE OF THE SERVICE.</li>
                                              <li><span dir="LTR"></span>PROMETHEUS DOES NOT WARRANT THAT THE SERVICE WILL BE<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>,</span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> AT ALL TIMES<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>,</span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> FULLY OPRATIONAL<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>,</span><span dir="LTR"></span><span dir="LTR"></span><span lang="HE"><span dir="LTR"></span><span dir="LTR"></span> </span>SECURED, ACCURATE, COMPLETE, UNINTERRUPTED<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>,</span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> ERROR FREE, FREE OF VIRUSES, WORMS, OTHER HARMFUL COMPONENTS AND PROGRAM LIMITATIONS.</li>
                                              <li><span dir="LTR"></span>PROMETHEUS MAY, AT ITS SOLE DISCRETION AND WITHOUT AN OBLIGATION TO DO SO, CORRECT, MODIFY, AMEND, ENHANCE, IMPROVE AND MAKE ANY OTHER REQUIRED CHANGES TO THE SERVICE AT ANY TIME, DISCONTINUE THE DISPLAY<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>,</span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> PROVIDING ANY CONTENT OR FEATURES<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>,</span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> WITHOUT ANY NOTICE PRIOR NOTICE.</li>
                                              <li><span dir="LTR"></span>YOU AGREE AND ACKNOWLEDGE THAT THE USE OF THE SERVICE, INCLUDING USE OF OR RELIANCE ON ANY CONTENT AVAILABLE THROUGH THE SERVICE, IS ENTIRELY, OR OTHERWISE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AT YOUR OWN RISK.</li>
                                            </ol>
                                            <h1>Limitation of Liability</h1>
                                            <ol start="79">
                                              <li><span dir="LTR"></span>IN NO EVENT SHALL PROMETHEUS AND/OR ANY OF THE PROMETHEUS AFFILIATES BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, RESULTING FROM OR ARISING OUT OF THE SERVICE, USE OR INABILITY TO USE THE SERVICE, FAILURE OF THE SERVICE TO PERFORM AS REPRESENTED OR EXPECTED, LOSS OF GOODWILL, DATA OR PROFITS, THE PERFORMANCE OR FAILURE TO PERFORM UNDER THESE TERMS, AND ANY OTHER ACT OR OMISSION OR BY ANY OTHER CAUSE WHATSOEVER, INCLUDING WITHOUT LIMITATION DAMAGES ARISING FROM THE CONDUCT OF ANY USERS AND/OR THIRD PARTY SERVICES.</li>
                                              <li><span dir="LTR"></span>NO ACTION MAY BE BROUGHT BY YOU FOR ANY BREACH OF THESE TERMS MORE THAN ONE (1) YEAR AFTER THE ACCRUAL OF SUCH CAUSE OF ACTION. AS SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, THEN SUCH LIMITATIONS ONLY MAY NOT APPLY TO A USER RESIDING IN SUCH JURISDICTION.</li>
                                              <li><span dir="LTR"></span>SUCH LIMITATIONS, EXCLUSIONS AND DISCLAIMERS SHALL APPLY TO ALL CLAIMS FOR DAMAGES, WHETHER BASED IN AN ACTION OF CONTRACT, WARRANTY, STRICT LIABILITY, NEGLIGENCE, TORT, OR OTHERWISE.</li>
                                              <li><span dir="LTR"></span>YOU HEREBY ACKNOWLEDGE AND AGREE THAT THESE LIMITATIONS OF LIABILITY ARE AGREED ALLOCATIONS OF RISK CONSTITUTING IN PART THE CONSIDERATION FOR PROMETHEUS’S SERVICES TO YOU, AND SUCH LIMITATIONS WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY, AND EVEN IF PROMETHEUS AND/OR ANY PROMETHEUS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LIABILITIES AND/OR DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT, SHALL PROMETHEUS’ CUMULATIVE LIABILITY TO YOU EXCEED AMOUNTS PAID TO PROMETHEUS FOR USE OF THE SERVICE IN THE LAST THREE (3) MONTHS.</li>
                                              <li><span dir="LTR"></span>IF YOU HAVE NOT MADE ANY PAYMENTS TO PROMETHEUS FOR THE USE OF THE SERVICE<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>, </span>INCLUDING DURING THE TRIAL PERIOD, THEN PROMETHEUS SHALL NOT HAVE ANY LIABILITY TOWARDS YOU.</li>
                                              <li><span dir="LTR"></span>NOTHING IN THESE TERMS SHALL LIMIT THE COMPANY’S LIABILITY TO USER FOR FRAUDULENT MISREPRESNTATION OR FOR ANY OTHER LIABILITY THAT, BYU LAW, MAY NOT BE LIMITED OR EXCLUDED.</li>
                                            </ol>
                                            <h1>Indemnification</h1>
                                            <ol start="85">
                                              <li><span dir="LTR"></span>You agree to defend, indemnify and hold harmless Prometheus and any Prometheus Affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs, debts, fines, late fees, cancellation fees and expenses (including attorney’s fees) arising directly or indirectly as a result of the use of the Service (or any part thereof) or a breach of these Terms by you, including third parties’ intellectual property rights, privacy rights and applicable legislation.</li>
                                            </ol>
                                            <h1>Amendment to Terms</h1>
                                            <ol start="86">
                                              <li><span dir="LTR"></span>The Company may change the Terms from time to time, at its sole discretion and without any notice, including the Privacy Policy.</li>
                                              <li><span dir="LTR"></span>Substantial changes of these Terms will be first notified on the Company’s Site. Such substantial changes will take effect seven (7) days after such notice was provided, otherwise, all other changes to these Terms are effective as of the stated “Last Revised” date and your continued use of the Service after the Last Revised date will constitute acceptance of, and agreement to be bound by those changes.</li>
                                              <li><span dir="LTR"></span>In the event that the Terms should be amended to comply with any legal requirements, such amendments may take effect immediately and without any prior notice, as may be required by law.</li>
                                            </ol>
                                            <h1>Termination of Service</h1>
                                            <ol start="89">
                                              <li><span dir="LTR"></span>At any time, The Company may block your access to the Service temporarily or permanently, for any reason, at the Company’s sole discretion, in addition to any other remedies that may be available to the Company under any applicable law. Such actions by the Company may be taken if Prometheus deems that a Customer, a User or you, have breached these Terms in any manner.</li>
                                              <li><span dir="LTR"></span>Prometheus may at any time, at its sole discretion, cease the operation of the Service or any part thereof, temporarily or permanently, without giving any prior notice. You agree and acknowledge that Prometheus does not assume any responsibility with respect to, or in connection with the termination of our Service’s operation and loss of any data.</li>
                                              <li><span dir="LTR"></span>The provisions of User Representations and Undertakings; Use Restrictions, Privacy Policy; Intellectual Property Right; Minors; Third Party Services; Third Party Components; Usage Rules; Disclaimer and Warranties; Limitation of Liability; Indemnification; and General will survive the termination or expiration of the contractual relationship between Customer and the Company.</li>
                                            </ol>
                                            <h1>General</h1>
                                            <ol start="92">
                                              <li><span dir="LTR"></span>These Terms do not, and shall not be construed to create any partnership, joint venture, employer-employee, agency, or franchisor-franchisee relationship between the parties hereto.</li>
                                              <li><span dir="LTR"></span>Any claim relating to the Service or the use of the Service will be governed by and interpreted in accordance with the laws of the State of Israel, without reference to its conflict-of-laws principles. Any dispute arising out of or related to your use of this Site and/or the App(s) will be brought in, and you hereby consent to exclusive jurisdiction and venue in, the competent courts of the District of Tel-Aviv, Israel.</li>
                                              <li><span dir="LTR"></span>If any provision of these Terms is found to be unlawful, void, or for any reason unenforceable, then that provision will be deemed severable from these Terms and will not affect the validity and enforceability of any remaining provision.</li>
                                              <li><span dir="LTR"></span>Notices to you may be made via email or regular mail or upon a message within the Service or a notice on Company’s Site. The Service may also provide notices of changes to these Terms or other matters, by displaying such notices or by providing links to such notices.</li>
                                              <li><span dir="LTR"></span>Without limitation, you agree that a printed version of these Terms and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</li>
                                            </ol>
                                            <h1>Misconduct and Copyright Agent Policy</h1>
                                            <ol start="97">
                                              <li><span dir="LTR"></span>If you believe a user or a third party acted inappropriately including, but not limited to, offensive, violent or sexually inappropriate behavior or content, please report immediately such user to the appropriate authorities and to the Company Promptly.</li>
                                              <li><span dir="LTR"></span>The Company enforces a zero-tolerance policy to objectionable content or abusive users.</li>
                                              <li><span dir="LTR"></span>If you believe that your work has been copied in a way that constitutes copyright infringement, please provide the Company a notice with the following information to the Company’s Copyright Agent: (i) a physical or electronic signature of a person authorized to act on behalf of the of the Copyright Owner; (ii) a description of the copyrighted work that has been allegedly infringed; (iii) a description of the infringing activity, sufficient to able the Company to locate the material (including URL address or screen capture of such infringing activity); (iv) your contact details (v) a statement that the information in the notification is accurate and, under penalty of perjury, you are the copyright owner or are authorized to act on behalf of the owner of a copyright that is allegedly infringed.</li>
                                              <li><span dir="LTR"></span>The Company’s Copyright Agent can be reached at: <b><u><a href="mailto:team@outicks.com">team@outicks.com</a></u></b></li>
                                              <li><span dir="LTR"></span>In the event that Prometheus receives a notice regarding copyright infringement, it may cancel any User’s account, take any webpage down, remove any content in its sole discretion, with or without prior notice.</li>
                                              <li><span dir="LTR"></span>In such case, a party that sees itself damaged by Prometheus’s actions, may file a proper counter-notice which must include: (i) full name, address, phone number and physical or electronic signature; (ii) identification of the material and its location before removal; (iii) a statement under penalty of perjury that the material was removed by mistake or misidentification; (iv) consent to an appropriate judicial body; and (v) any other information required under the relevant applicable law.</li>
                                              <li><span dir="LTR"></span>Prometheus reserves the right to notify the person or entity providing the infringement notice of such counter-notice and provide any details included therein.</li>
                                            </ol>
                                            <h1>Contact</h1>
                                            <ol start="104">
                                              <li><span dir="LTR"></span>Any inquiries or clarifications concerning the Terms, or the Service, can be sent to the Company by email to the following address: <b><u><a href="mailto:team@outicks.com">team@outicks.com</a></u></b>.</li>
                                              <li><span dir="LTR"></span>The Company will make an effort to reply to such notice within a reasonable timeframe.</li>
                                            </ol>
                                            &nbsp;
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    <Footer />
  </div>

  <script
    dangerouslySetInnerHTML={{
        __html: `
          jQuery(document).ready(function(){jQuery('#menu-item-92 a').addClass('elementor-button-link elementor-button').css('background-color','#C60051').css('color','white');});
          `
    }}
  />

  <button className="hestia-scroll-to-top">
      <i className="fa fa-angle-double-up" aria-hidden="true"></i>
  </button>

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var requestpost={"ajaxurl":"https:\/\/outicks.com\/wp-admin\/admin-ajax.php","disable_autoslide":"","masonry":""};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var elementorFrontendConfig={"environmentMode":{"edit":false,"wpPreview":false},"is_rtl":"","breakpoints":{"xs":0,"sm":480,"md":768,"lg":1025,"xl":1440,"xxl":1600},"version":"2.4.6","urls":{"assets":"https:\/\/outicks.com\/wp-content\/plugins\/elementor\/assets\/"},"settings":{"page":[],"general":{"elementor_global_image_lightbox":"yes","elementor_enable_lightbox_in_editor":"yes"}},"post":{"id":44,"title":"Time Off Management","excerpt":""}};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        COOKIES_ENABLER.init({scriptClass:'ginger-script',iframeClass:'ginger-iframe',acceptClass:'ginger-accept',disableClass:'ginger-disable',dismissClass:'ginger-dismiss',bannerClass:'ginger_banner-wrapper',bannerHTML:document.getElementById('ginger-banner-html')!==null?document.getElementById('ginger-banner-html').innerHTML:'<div className="ginger_banner bottom dialog dark ginger_container ginger_container--open">'+'<p className="ginger_message">'+'<!--googleoff: index-->By using this site, you agree to our updated <a href=\"/privacy-policy\">Privacy Policy<\/a> and our <a href=\"/terms-of-service\">Terms of Use<\/a>.<!--googleon: index-->'+'</p>'+'<a href="#" className="ginger_btn ginger-accept ginger_btn_accept_all">'+'Accept Cookie'+'<\/a>'+'<\/div>',cookieDuration:365000,eventScroll:false,scrollOffset:20,clickOutside:true,cookieName:'ginger-cookie',forceReload:false,iframesPlaceholder:true,iframesPlaceholderClass:'ginger-iframe-placeholder',iframesPlaceholderHTML:document.getElementById('ginger-iframePlaceholder-html')!==null?document.getElementById('ginger-iframePlaceholder-html').innerHTML:'<p><!--googleoff: index-->This website uses cookies.<!--googleon: index-->'+'<a href="#" className="ginger_btn ginger-accept">Accept Cookie</a>'+'<\/p>'});
        `
    }}
  />
</>   
)}

}
